import React from "react"
import {Container, PrimaryColor} from "../Layout/Layout.styles";
import {HeroContainer, HeroH1, HeroH3, HeroH6, HeroP, DisplayH2, HeroWrapper, HeroWrapperOne, HeroWrapperTwo, HeroContactWrapper, HeroContactItemWrapper} from "./Hero.styles";
import ContactForm from "../ContactForm/ContactForm";

import {useTranslation} from 'gatsby-plugin-react-i18next';
import {MailIcon, PhoneIcon} from "../../assets/svg/icons-and-logos";

const Hero = () => {

    const {t} = useTranslation();

    return (
        <div>
            <HeroContainer/>
            <Container alignCenter>
                <HeroWrapper>
                    <HeroWrapperOne>
                        <HeroH1>{t("Domain")} <PrimaryColor>{t("DomainName")}.com</PrimaryColor> <br/> {t("ForSale")}</HeroH1>
                        <HeroP>{t("Text")}</HeroP>

                        <HeroContactWrapper>
                            <HeroContactItemWrapper>
                                <PhoneIcon/>
                                <HeroH6 href="tel:+370 636 52583">+370 636 52583</HeroH6>
                            </HeroContactItemWrapper>
                            <HeroContactItemWrapper>
                                <MailIcon/>
                                <HeroH6 href="mailto:benkuds@gmail.com">benkuds@gmail.com</HeroH6>
                            </HeroContactItemWrapper>
                        </HeroContactWrapper>

                        <HeroH6>{t("MinimalOffer")}</HeroH6>
                        <DisplayH2>€ 1600</DisplayH2>
                    </HeroWrapperOne>

                    <HeroWrapperTwo>
                        <HeroH3>{t("OfferHeadline")}</HeroH3>
                        <ContactForm/>
                    </HeroWrapperTwo>

                </HeroWrapper>
            </Container>
        </div>
    )
};

export default Hero