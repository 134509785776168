import React from "react";

export const MailIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="15"
        viewBox="0 0 20 15"
        className={props.className}
        fill={props.fill}
    >
        <path
            fill="#1AD9B3"
            d="M18.125 0c.52 0 .963.182 1.328.547.365.364.547.807.547 1.328v11.25c0 .52-.182.963-.547 1.328a1.808 1.808 0 01-1.328.547H1.875c-.52 0-.964-.182-1.328-.547A1.808 1.808 0 010 13.125V1.875C0 1.355.182.911.547.547A1.808 1.808 0 011.875 0h16.25zM1.875 1.25a.64.64 0 00-.469.195.58.58 0 00-.156.43v1.602c.911.78 2.878 2.37 5.898 4.765l.547.469c.365.286.638.495.82.625.183.13.417.273.704.43.312.156.573.234.781.234.182 0 .378-.04.586-.117.208-.078.417-.195.625-.352.234-.156.43-.3.586-.43.182-.13.39-.286.625-.468.234-.209.377-.339.43-.39 3.02-2.397 4.986-3.985 5.898-4.766V1.875a.52.52 0 00-.195-.43.52.52 0 00-.43-.195H1.875zm16.25 12.5a.581.581 0 00.43-.156.64.64 0 00.195-.469V5.117L13.633 9.22c-.052.026-.235.169-.547.43-.287.26-.52.455-.703.585-.182.13-.417.287-.703.47a4.415 4.415 0 01-.86.429c-.286.078-.56.117-.82.117-.234 0-.482-.04-.742-.117a5.068 5.068 0 01-.664-.274 37.626 37.626 0 01-.664-.39c-.235-.182-.43-.326-.586-.43-.157-.13-.339-.286-.547-.469a5.27 5.27 0 00-.43-.351c-2.5-2.005-4.206-3.373-5.117-4.102v8.008c0 .182.052.338.156.469.13.104.287.156.469.156h16.25z"
        ></path>
    </svg>
);

export const PhoneIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        className={props.className}
        fill={props.fill}
    >
        <path
            fill="#1AD9B3"
            d="M19.102 1.438c.182.052.338.143.468.273.157.104.274.234.352.39.078.157.117.326.117.508 0 4.948-1.745 9.167-5.234 12.657-3.49 3.49-7.709 5.234-12.657 5.234a1.19 1.19 0 01-.742-.273 1.103 1.103 0 01-.43-.665l-.898-3.945a.675.675 0 01-.039-.39c.026-.157.065-.3.117-.43.078-.13.17-.235.274-.313.104-.104.221-.195.351-.273L5 12.414c.573-.26 1.055-.143 1.445.352l1.72 2.109c2.786-1.38 4.869-3.463 6.25-6.25l-2.11-1.719c-.495-.39-.612-.872-.352-1.445l1.797-4.219a1.25 1.25 0 01.586-.625c.26-.13.534-.156.82-.078l3.946.898zM2.187 19.25c2.24 0 4.389-.43 6.446-1.29 2.057-.884 3.815-2.07 5.273-3.554 1.485-1.484 2.67-3.242 3.555-5.273a16.23 16.23 0 001.328-6.485l-3.906-.898-1.797 4.219 2.851 2.304c-.937 2.032-2.018 3.66-3.242 4.883-1.224 1.224-2.851 2.305-4.883 3.242l-2.304-2.851-4.219 1.797.899 3.906z"
        ></path>
    </svg>
);





