import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/SEO/Seo"
import Hero from "../components/Hero/Hero"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";

const IndexPage = () => {

    const {t} = useTranslation();

    return (
        <Layout>
            <Seo title={t('Home')}/>
            <Hero/>
        </Layout>
    )
};

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;