import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {Form, InputField, SucceededMessage, HiddenField, FormLabel, ContactLabelH6, FormInlineWrapper, FormInsideWrapper, FormLabelWithIcon} from "./ContactForm.styles";
import {ButtonSubmit, PrimaryColor} from "../Layout/Layout.styles";
import {useTranslation} from "gatsby-plugin-react-i18next";


const ContactForm = () => {

    const {t} = useTranslation();

    const [state, handleSubmit] = useForm("mrgrnlkw");

    if (state.succeeded) {
        return (
            <SucceededMessage>{t("ThankYouForYourOffer")}</SucceededMessage>
        )
    }


    return (

            <Form onSubmit={handleSubmit}>

                <FormInsideWrapper>
                    <HiddenField
                        id={t("DomainName")}
                        type={t("DomainName")}
                        name={t("DomainName")}
                        placeholder={t("DomainName")}
                    />

                    <FormInlineWrapper>
                        <FormLabel>
                            <ContactLabelH6>{t("YourName")} <PrimaryColor>*</PrimaryColor></ContactLabelH6>
                            <ValidationError
                                prefix="Name"
                                field="name"
                                errors={state.errors}
                            />
                            <InputField
                                required
                                id="name"
                                type="name"
                                name="name"
                                placeholder={t("Name")}
                            />
                        </FormLabel>


                        <FormLabel>
                            <ContactLabelH6>{t("YourEmail")} <PrimaryColor>*</PrimaryColor></ContactLabelH6>
                            <ValidationError
                                prefix="Name"
                                field="name"
                                errors={state.errors}
                            />
                            <InputField
                                required
                                id="email"
                                type="email"
                                name="email"
                                placeholder={t("Email")}
                            />
                        </FormLabel>
                    </FormInlineWrapper>


                    <FormLabel>
                        <ContactLabelH6>{t("YourPhoneNumber")} <PrimaryColor>*</PrimaryColor></ContactLabelH6>
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                        <InputField
                            required
                            id="number"
                            type="text"
                            name="number"
                            placeholder={t("PhoneNumber")}
                        />
                    </FormLabel>

                    <FormLabelWithIcon>
                        <ContactLabelH6>{t("YourOffer")} <PrimaryColor>*</PrimaryColor></ContactLabelH6>
                        <ValidationError
                            prefix="number"
                            field="number"
                            errors={state.errors}
                        />
                        <p>€</p>
                        <InputField
                            required
                            id="amount"
                            type="number"
                            name="amount"
                            placeholder={t("Offer")}
                        />
                    </FormLabelWithIcon>
                </FormInsideWrapper>

                <ButtonSubmit big="true" type="submit" disabled={state.submitting}>
                    {t("SendOffer")}
                </ButtonSubmit>
            </Form>


    );
};

export default ContactForm;